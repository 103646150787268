@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
     :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

html {
    height: 100%;
}

#__next {
    height: 100%;
}

body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient( to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
    height: 100%;
    font-family: "Poppins";
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}


/* Show vertical scrollbar on hover */

*:hover::-webkit-scrollbar {
    width: 6px;
}


/* Track */

*::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

*::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}


/* Handle on hover */

*::-webkit-scrollbar-thumb:hover {
    background: #555;
}